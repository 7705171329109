<template>
  <v-container fluid>
    <BreadHeader :title="model.add.name">
      <v-btn color="secondary" @click="redirectBack()">Back </v-btn>
    </BreadHeader>

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <!--BOC:[contents]-->
    <v-card>
      <v-toolbar flat color="#063058" dark>
        <v-toolbar-title>Price Setting</v-toolbar-title>
      </v-toolbar>
      <v-tabs vertical>
        <v-tab>
          <v-icon left> mdi-percent </v-icon>
          Tax Rate
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <component
              :is="taxRateTab"
              :params="params"
              @toggleTaxRatePage="toggleTaxRatePage"
            ></component>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import TaxRateAdd from "./taxrate/Add";
import Service from "@/objects/service";
import TaxRateIndex from "./taxrate/Index";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tax_summit/objects/globalData";

export default {
  mixins: [hrdcFunction],
  components: {
    TaxRateIndex,
    TaxRateAdd,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationSingleDetail: new Api(),
    apiSubmit: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    formData: {
      applicationNo: null,
    },
    errorField: {
      applicationNo: null,
    },
    loadingDialog: false,
    btnDisabled: false,
    taxRateTab: "TaxRateIndex",
  }),
  watch: {
    //
  },
  async created() {
    this.loadingDialog = !this.loadingDialog;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/claim_approved/update-claim-approved`,
        method: "post",
      },
      apiGetApplicationSingleDetail: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid:
            this.$store.state.hrdc_tax_summit.data.applicationUuid,
        },
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    this.rolesAssignation(this.auth.roles);
    // if (!this.isTaxpodAdminRoles) {
    //   this.alertError.push("You are not allowed to perform this action!");
    //   this.btnDisabled = true;
    // }
    this.loadingDialog = !this.loadingDialog;
  },
  mounted() {},
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    //EOC
    redirectBack() {
      let route = "HrdcTaxSummitSettings";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
      });
    },
    toggleTaxRatePage(redirectToComponent) {
      this.taxRateTab = redirectToComponent;
    },
    async submit() {
      this.loadingDialog = !this.loadingDialog;
      let data = {
        applicationUuid: this.formData.applicationNo,
        claimApprovedDate: this.formData.claimApprovedDate,
        referById: this.auth.uuid,
        editMode: this.editMode,
      };

      this.apiSubmit.setParams(data);
      this.apiSubmit.fetch();
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.loadingDialog = !this.loadingDialog;
          if (!response.status) {
            this.showErrorMessage(response);
            this.$store.dispatch("showMessage", {
              message: response.message,
              messageType: "error",
              timeout: 2000,
            });
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcTaxSummitApplication",
              query: {
                viewId: this.$store.state.hrdc_tax_summit.data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
    },
  },
};
</script>

<style>
.v-slide-group__content {
  border-right: 1px solid #e8e8e8;
}
</style>
